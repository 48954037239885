import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service';
import { ImportAPI } from '../../services/apis/import.api';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  private _importApi: ImportAPI;

  fromDate;
  toDate;
  statusArrayStr;
  importOrderId;
  importProductId;

  constructor(public generalService: GeneralService, private _http: HttpClient) {
    this._importApi = new ImportAPI(generalService.getCurrentBaseUrl(), _http);
  }

  ngOnInit(): void {
  }

  importCustomers() {
    this._importApi.importCustomers().subscribe();
  }

  importOrders() {
    this._importApi.importOrders(this.fromDate, this.toDate, this.statusArrayStr).subscribe();
  }

  importOrder() {
    this._importApi.importOrder(this.importOrderId).subscribe();
  }

  importPendingOrder() {
    this._importApi.importPendingOrder().subscribe();
  }

  importCategories() {
      this._importApi.importCategory().subscribe();
  }

  importTags() {
    this._importApi.importWCTag().subscribe();
  }

  importProducts() {
    this._importApi.importProducts().subscribe();
  }

  importProduct() {
    this._importApi.importProduct(this.importProductId).subscribe();
  }

  importOrderNotes() {
    this._importApi.importOrderNotes(this.importOrderId).subscribe();
  }

  updateAllProductsFromAdmin() {
    this._importApi.updateProductFromAdmin().subscribe();
  }
}
