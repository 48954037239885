<div class="container-fluid">
  <div class="row">
    <button (click)="importCustomers()">Import Customers</button>
  </div>
  <div class="row">
    <p>sample "2020-02-24T00:00:00"</p>
    <p>Import From<input type="text" [(ngModel)]="fromDate" /></p>
    <p>To<input type="text" [(ngModel)]="toDate" /></p>
    <p>Statuses<input type="text" [(ngModel)]="statusArrayStr" /></p>
    <button (click)="importOrders()">Update Orders</button>
  </div>
  <div class="row">

    <p>OrderId<input type="text" [(ngModel)]="importOrderId" /></p>
    <button (click)="importOrder()">Update Order</button>
    <button (click)="importPendingOrder()">Update Pending Order</button>
    <button (click)="importOrderNotes()">Import Order Notes</button>
  </div>
  <div class="row">
    <button (click)="importCategories()">Import Categories</button>
  </div>
  <div class="row">
    <button (click)="importTags()">Import Tags</button>
  </div>
  <div class="row">
    <p>Product Id<input type="text" [(ngModel)]="importProductId" /></p>
    <button (click)="importProduct()">Update Product</button>
  </div>
  <div class="row">
    <button (click)="importProducts()">Update All Products</button>
  </div>
  <div class="row">
    <button (click)="updateAllProductsFromAdmin()">Update All Products from Admin</button>
  </div>
</div>
