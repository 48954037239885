import { Injectable } from '@angular/core';
import { GeneralResource } from '../models/general-resource.model';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  public statuses: GeneralResource[] = [];

  constructor() {
    
  }

  loadStatuses() {
    this.statuses.push({ Id: 5, Name: 'Processing' });
    this.statuses.push({ Id: 6, Name: 'On-Hold' });
    this.statuses.push({ Id: 7, Name: 'Completed' });
    this.statuses.push({ Id: 2, Name: 'Pending' });
    this.statuses.push({ Id: 8, Name: 'Canclled' });
    this.statuses.push({ Id: 10, Name: 'Failed' });
  }
}
