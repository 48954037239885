import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { packingRoutes } from './packing.routing';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PackingComponent } from './packing.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(packingRoutes)
  ],
  declarations: [
    PackingComponent,
  DashboardComponent]
})
export class PackingModule {}
