


import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { GridService } from '../kendo-grid/grid.service';



import { DataSourceRequestState } from '@progress/kendo-data-query'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { IDList } from '../../models/idlist.model';
import { OrderDetail } from '../../models/order-detail.model';
import { Order } from '../../models/order.model';
import { OrderNote } from '../../models/order-note.model';
import { PackingLineItem } from '../../models/packing-line-item.model';
import { OrderIdLookup } from '../../models/order-id-lookup.model';

//@Injectable()
export class OrderAPI {
    private _baseUrl: string;
    private _http: HttpClient;
    private _gridService: GridService

    constructor(
        baseUrl: string,
        http: HttpClient,
        gridService: GridService = null
    ) {
        this._baseUrl = baseUrl; 
        this._http = http;
        this._gridService = gridService;
    }


    


    
    public getOrderInvoices(ids: IDList): Observable<OrderDetail[]> { 
        const _Url = this._baseUrl + `api/Order/GetOrderInvoices`;               
        return this._http.post<OrderDetail[]>(_Url, ids);
        // .catch(error => this.handleError(error));
    }
    public getOrder(OrderId: number): Observable<OrderDetail> { 
        const _Url = this._baseUrl + `api/Order/GetOrder/${OrderId}`;               
        return this._http.get<OrderDetail>(_Url);
        // .catch(error => this.handleError(error));
    }
    public getOrders(): Observable<Order[]> { 
        const _Url = this._baseUrl + `api/Order/GetOrders`;               
        return this._http.get<Order[]>(_Url);
        // .catch(error => this.handleError(error));
    }
    public insertOrderNotes(OrderId: number, newNote: OrderNote): Observable<void> { 
        const _Url = this._baseUrl + `api/Order/InsertOrderNotes/${OrderId}`;               
        return this._http.post<void>(_Url, newNote);
        // .catch(error => this.handleError(error));
    }
    public deleteOrderNote(OrderId: number): Observable<void> { 
        const _Url = this._baseUrl + `api/Order/DeleteOrderNote/${OrderId}`;               
        return this._http.delete<void>(_Url);
        // .catch(error => this.handleError(error));
    }
    public checkPALOk(OrderId: number, palChecked: number): Observable<void> { 
        const _Url = this._baseUrl + `api/Order/SwitchPALOk/${OrderId}?palChecked=${palChecked}`;               
        return this._http.post<void>(_Url, null);
        // .catch(error => this.handleError(error));
    }
    public partiallyShipped(OrderId: number, partiallyShipped: number): Observable<void> { 
        const _Url = this._baseUrl + `api/Order/PartiallyShipped/${OrderId}?partiallyShipped=${partiallyShipped}`;               
        return this._http.post<void>(_Url, null);
        // .catch(error => this.handleError(error));
    }
    public orderStatusUpdate(OrderId: number, StatusId: number): Observable<void> { 
        const _Url = this._baseUrl + `api/Order/OrderStatus/${OrderId}/${StatusId}`;               
        return this._http.put<void>(_Url, null);
        // .catch(error => this.handleError(error));
    }
    public getOrderPackingSummary(orderIds: IDList): Observable<PackingLineItem[]> { 
        const _Url = this._baseUrl + `api/Order/GetOrderPackingSummary`;               
        return this._http.post<PackingLineItem[]>(_Url, orderIds);
        // .catch(error => this.handleError(error));
    }
    public getOrderIdByWCId(WCId: number): Observable<OrderIdLookup> { 
        const _Url = this._baseUrl + `api/Order/GetOrderIdByWCId/${WCId}`;               
        return this._http.get<OrderIdLookup>(_Url);
        // .catch(error => this.handleError(error));
    }

    
    public getOpenOrderList(request: DataSourceRequestState): Observable<any> { 
        const _Url = this._baseUrl + `api/Order/GetOpenOrderList`;               
        return this._gridService.processGridRequest(request, _Url);
        // .catch(error => this.handleError(error));
    }
    public getAllOrderList(request: DataSourceRequestState): Observable<any> { 
        const _Url = this._baseUrl + `api/Order/GetAllOrderList`;               
        return this._gridService.processGridRequest(request, _Url);
        // .catch(error => this.handleError(error));
    }

    // Utility
    /*
    public handleError(error: HttpErrorResponse) {
        console.error("Error Caught!", error);
        let customError: string = "";
        if (error.error) {
            let errorMessage = error.status === 400 ? error.error : error.statusText;
            if (error.status === 400) {
               errorMessage = JSON.parse(errorMessage).Message;
            }  else {
                if (!error.error.message) {
                    errorMessage = error.error.Message ? error.error.Message.Message : errorMessage;                
                } else {
                    errorMessage = error.error.message;
                }
            }            
            customError = error.status === 400 ? error.error : errorMessage;
            // this._toastr.error(customError, 'An Error Has Occurred');
        } else {
        }
        return Observable.throw(customError || 'Server error');
    }*/
}
