


import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { GridService } from '../kendo-grid/grid.service';



import { DataSourceRequestState } from '@progress/kendo-data-query'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { ProductDetail } from '../../models/product-detail.model';
import { GeneralResource } from '../../models/general-resource.model';
import { VariationDetail } from '../../models/variation-detail.model';

//@Injectable()
export class ProductAPI {
    private _baseUrl: string;
    private _http: HttpClient;
    private _gridService: GridService

    constructor(
        baseUrl: string,
        http: HttpClient,
        gridService: GridService = null
    ) {
        this._baseUrl = baseUrl; 
        this._http = http;
        this._gridService = gridService;
    }


    


    
    public getProduct(ProductId: number): Observable<ProductDetail> { 
        const _Url = this._baseUrl + `api/Product/GetProduct/${ProductId}`;               
        return this._http.get<ProductDetail>(_Url);
        // .catch(error => this.handleError(error));
    }
    public getVariationList(ProductId: number): Observable<GeneralResource[]> { 
        const _Url = this._baseUrl + `api/Product/GetVariationList/${ProductId}`;               
        return this._http.get<GeneralResource[]>(_Url);
        // .catch(error => this.handleError(error));
    }
    public getVariation(VariationId: number): Observable<VariationDetail> { 
        const _Url = this._baseUrl + `api/Product/GetVariation/${VariationId}`;               
        return this._http.get<VariationDetail>(_Url);
        // .catch(error => this.handleError(error));
    }
    public markOutOfStock(ProductId: number, OutOfStock: number): Observable<void> { 
        const _Url = this._baseUrl + `api/Product/MarkOutOfStock/${ProductId}?OutOfStock=${OutOfStock}`;               
        return this._http.post<void>(_Url, null);
        // .catch(error => this.handleError(error));
    }
    public updateProductQuantity(ProductId: number, Quantity: number): Observable<void> { 
        const _Url = this._baseUrl + `api/Product/UpdateProductQuantity/${ProductId}?Quantity=${Quantity}`;               
        return this._http.put<void>(_Url, null);
        // .catch(error => this.handleError(error));
    }

    
    public getProductList(request: DataSourceRequestState): Observable<any> { 
        const _Url = this._baseUrl + `api/Product/GetProductList`;               
        return this._gridService.processGridRequest(request, _Url);
        // .catch(error => this.handleError(error));
    }
    public getProductOrderList(ProductId: number, request: DataSourceRequestState): Observable<any> { 
        const _Url = this._baseUrl + `api/Product/GetProductOrderList/${ProductId}`;               
        return this._gridService.processGridRequest(request, _Url);
        // .catch(error => this.handleError(error));
    }
    public getVariationOrderList(VariationId: number, request: DataSourceRequestState): Observable<any> { 
        const _Url = this._baseUrl + `api/Product/GetVariationOrderList/${VariationId}`;               
        return this._gridService.processGridRequest(request, _Url);
        // .catch(error => this.handleError(error));
    }

    // Utility
    /*
    public handleError(error: HttpErrorResponse) {
        console.error("Error Caught!", error);
        let customError: string = "";
        if (error.error) {
            let errorMessage = error.status === 400 ? error.error : error.statusText;
            if (error.status === 400) {
               errorMessage = JSON.parse(errorMessage).Message;
            }  else {
                if (!error.error.message) {
                    errorMessage = error.error.Message ? error.error.Message.Message : errorMessage;                
                } else {
                    errorMessage = error.error.message;
                }
            }            
            customError = error.status === 400 ? error.error : errorMessage;
            // this._toastr.error(customError, 'An Error Has Occurred');
        } else {
        }
        return Observable.throw(customError || 'Server error');
    }*/
}
