import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';

/* Kendo Angular Modules */
import { PopupModule } from '@progress/kendo-angular-popup';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';


import { NavbarModule, WavesModule, IconsModule, CardsModule } from 'angular-bootstrap-md'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from '../services/auth/auth.service';

import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card'; 


import { FilterCheckboxComponent } from '../kendo-customize/filter-checkbox/filter-checkbox.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // HttpModule,
    RouterModule,
    GridModule,
    PDFModule,
    ExcelModule,
    DropDownsModule,
    DatePickerModule,
    PopupModule,
    ButtonsModule,
    DateInputsModule,
    ExcelExportModule,
    NavbarModule,
    WavesModule,
    IconsModule,
    CardsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FontAwesomeModule,
    MatCardModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    //  HttpModule,
    RouterModule,
    GridModule,
    PDFModule,
    ExcelModule,
    DropDownsModule,
    DatePickerModule,
    PopupModule,
    ButtonsModule,
    DateInputsModule,
    ExcelExportModule,
    NavbarModule,
    WavesModule,
    IconsModule,
    CardsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    MatDatepickerModule,
    FilterCheckboxComponent,
    MatNativeDateModule,
    FontAwesomeModule,
    MatCardModule
  ],
  declarations: [
    FilterCheckboxComponent
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule { }
