<div *ngIf="pageLoading || gridService.gridLoading" class="k-i-loading gridLoading-state"></div>
<div class="container-fluid product-detail" *ngIf="productDetail">
  <mdb-card class="w-100 h-100">
    <mdb-card-header>{{productDetail.Name}}</mdb-card-header>
    <mdb-card-body>
      <div class="row h-100">
        <div class="col-4">
          <mdb-card-title>
            <h5>Information</h5>
          </mdb-card-title>
          <div class="row">
            <div class="col-5">SKU</div>
            <div class="col-5">{{productDetail.SKU}}</div>
          </div>
          <div class="row">
            <div class="col-5">Price</div>
            <div class="col-5">{{productDetail.Price}}</div>
          </div>
          <div class="row">
            <div class="col-5">Product Tags</div>
            <div class="col-5">{{productDetail.TagsStrList ? productDetail.TagsStrList.join(',') : ''}}</div>
          </div>
          <div class="row">
            <div class="col-5">Product Categories</div>
            <div class="col-5"><span *ngFor="let cate of productDetail.CategoryList" class="product-category-list-item">{{cate.Name}}</span></div>
          </div>
          <div class="row">
            <div class="col-5">Stock Quantity</div>
            <div class="col-5">
              {{productDetail.StockQuantity}}
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-5 font-weight-bold">Admin Backorder</div>
            <div class="col-5"><mat-checkbox [(ngModel)]="productDetail.AdminOutOfStock" name="AdminOutOfStock" (change)="MarkOutOfStock()" ></mat-checkbox></div>
          </div>
        </div>
        <div class="col-8 h-100">
          <mdb-card-title>
            <h5>Related Orders</h5>
          </mdb-card-title>
          <div class="grid-container">
            <kendo-grid #productOrderGrid
                        [data]="gridData | async"
                        [pageSize]="state.take"
                        [skip]="state.skip"
                        [sort]="state.sort"
                        [sortable]="{
                        allowUnsort: true,
                        mode: 'multiple',
                        showIndexes: false
                        }"
                        [resizable]="true"
                        [pageable]="true"
                        [reorderable]="true"
                        [scrollable]="'scrollable'"
                        [groupable]="false"
                        filterable="menu"
                        [filter]="state.filter"
                        (dataStateChange)="dataStateChange($event)">

              <kendo-grid-column field="OrderId"
                                 title="Order Id"
                                 width="60">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
                  <a href="/orders/detail/{{dataItem.OrderId}}" target="_blank">{{dataItem.WCOrderId}}</a>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="OrderDate"
                                 title="Order Date"
                                 width="60">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
                  {{dataItem.OrderDate | date: 'yyyy-MM-dd HH:mm'}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="OrderStatus"
                                 title="Order Status"
                                 width="70">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
                  <span class="order-status badge badge-{{generalService.convertOrderStatus(dataItem.OrderStatus)}}">{{dataItem.OrderStatus | uppercase}}</span>
                </ng-template>
                <ng-template kendoGridFilterMenuTemplate
                             let-column="column"
                             let-filter="filter"
                             let-filterService="filterService">
                  <app-filter-checkbox [data]="filterCheckboxService.orderStatusFilters"
                                       [field]="column.field"
                                       [currentFilter]="filter"
                                       [filterService]="filterService"
                                       [isPrimitive]="true">
                  </app-filter-checkbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="Quantity"
                                 title="Quantity"
                                 width="60">
              </kendo-grid-column>
              <kendo-grid-column field="IsPartiallyShipped"
                                 title="Partially Shipped"
                                 width="60">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                  <kendo-grid-boolean-filter-menu [column]="column"
                                                  [filter]="filter"
                                                  [filterService]="filterService">
                  </kendo-grid-boolean-filter-menu>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </div>
          
        </div>
      </div>
    </mdb-card-body>
     
  </mdb-card>
</div>
