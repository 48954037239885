import { Injectable } from '@angular/core';
//import { OrderAPI } from './order.api';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../general.service';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { Observable, BehaviorSubject } from 'rxjs';
import { KendoDataService } from './kendo.data.service';
import { map, catchError, finalize } from 'rxjs/operators';
//import { ProductAPI } from './product.api';

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable({
  providedIn: 'root'
})
export class GridService extends BehaviorSubject<any>{

  //private _orderAPI: OrderAPI;
  //private _productAPI: ProductAPI;

  public originalData: any[] = [];
  public data: GridDataResult = null;
  public gridLoading: boolean;

  constructor(private _http: HttpClient, private _generalService: GeneralService, private _kendoDataService: KendoDataService) {
    super('');
    //this._orderAPI = new OrderAPI(_generalService.getCurrentBaseUrl(), this._http);
    //this._productAPI = new ProductAPI(_generalService.getCurrentBaseUrl(), this._http);
  }

  //getOrderGrids(state: DataSourceRequestState): Observable<any> {
  //  const url = this._generalService.getCurrentBaseUrl() + 'api/Order/GetOrderList';
  //  return this.processGridRequest(state, url);
  //}

  //getProductGrids(state: DataSourceRequestState): Observable<any> {
  //  const url = this._generalService.getCurrentBaseUrl() + 'api/Product/GetProductList';
  //  return this.processGridRequest(state, url);
  //}

  processGridRequest(state, url): Observable<any> {
    if (!this.gridLoading) {
      this.gridLoading = true;
    }

    this.reset();

    return this._kendoDataService.fetch(state, url)
      .pipe(map(gridResult => {
        if (gridResult) {
          this.data = gridResult;
        }
        super.next(gridResult);
      }), finalize(() => { this.gridLoading = false; }));
  }

  public reset() {
    this.data = undefined;
    super.next(this.data);
  }
}
