import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { GeneralService } from '../../services/general.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  reportDate;

  constructor(public generalService: GeneralService,
    private _http: HttpClient,
    private datePipe: DatePipe) {
  }

  ngOnInit(): void {
  }

  generateReport() {
    const newDateFormat = this.datePipe.transform(this.reportDate, 'yyyy-MM-dd');
    const _Url = this.generalService.getCurrentBaseUrl() + `api/Report/GetQuickBookReport?reportDateStr=${encodeURIComponent(newDateFormat)}`;
    return this._http.get(_Url, { responseType: 'blob' }).subscribe(data => {
      const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const blob = new Blob([data], { type: contentType });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = newDateFormat + 'report.xlsx';
      document.body.appendChild(element);
      element.click();
    });
  }


  //public getQuickBookReport(reportDateStr: string): Observable<void> {
  //  const _Url = this._baseUrl + `api/Report/GetQuickBookReport?reportDateStr=${encodeURIComponent(reportDateStr)}`;
  //  return this._http.get<void>(_Url);
  //  // .catch(error => this.handleError(error));
  //}
}
