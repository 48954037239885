import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { Order } from '../../models/order.model';
import { Observable, BehaviorSubject, empty } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { GridDataResult, GridComponent, DataStateChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import {
  State,
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from '@progress/kendo-data-query';
import { of } from 'rxjs';
import { GridService } from '../../services/kendo-grid/grid.service';
import { OrderAPI } from '../../services/apis/order.api';
import { GeneralService } from '../../services/general.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FilterCheckboxService } from '../../services/kendo-grid/filter-checkbox.service';
import Swal from 'sweetalert2';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { InvoiceService } from '../../services/invoice.service';
import { IDList } from '../../models/idlist.model';
import { OrderDetail } from '../../models/order-detail.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public openOrderGridData: Observable<GridDataResult>;
  public allOrderGridData: Observable<GridDataResult>;
  private _orderApi: OrderAPI
  public currentTab = 0;
  public currentSelectedItems: any[] = [];
  public openOrderState: State = {
    skip: 0,
    take: 100
  }

  public allOrderState: State = {
    skip: 0,
    take: 100
  }

  iconRedo = faRedo;

  //@ViewChild('orderGrid') orderGrid: GridComponent;

  constructor(public gridService: GridService,
    public generalService: GeneralService,
    private _http: HttpClient,
    public filterCheckboxService: FilterCheckboxService,
    private _invoiceService: InvoiceService) {
    this._orderApi = new OrderAPI(generalService.getCurrentBaseUrl(), _http, gridService);

  }

  ngOnInit(): void {
    this.filterCheckboxService.loadOrderStatus();
    this.loadCurrentGrid();
  }

  loadCurrentGrid() {
    this.gridService.reset();
    this.currentSelectedItems = [];
    switch (this.currentTab) {
      case 1:
        this.loadOpenOrders();
        break;
      case 0:
        this.loadAllOrders();
        break;
    }
  }

  loadOpenOrders() {
    this.gridService.reset();
    this.openOrderGridData = this.gridService.pipe(map(data => { return data; }));

    //this.gridService.getOrderGrids(this.state).subscribe();
    this._orderApi.getOpenOrderList(this.openOrderState)
      .pipe(catchError(err => {
        if (err instanceof HttpErrorResponse) {
          console.log(err);
          Swal.fire('Server Error', 'Please try again later.', 'error');
        }

        return of(empty);
      })).subscribe();
  }

  loadAllOrders() {
    this.gridService.reset();
    this.allOrderGridData = this.gridService.pipe(map(data => { return data; }));

    //this.gridService.getOrderGrids(this.state).subscribe();
    this._orderApi.getAllOrderList(this.allOrderState)
      .pipe(catchError(err => {
        Swal.fire('Server Error','Please try again later.','error');
        return of(empty);
      })).subscribe();
  }

  public dataStateChangeOpenOrder(state: DataStateChangeEvent): void {

    this.openOrderState = state;
    this.loadOpenOrders();
  }

  public dataStateChangeAllOrder(state: DataStateChangeEvent): void {

    this.allOrderState = state;
    this.loadAllOrders();
  }

  public selectedItemBy(context: RowArgs): any {
    return context.dataItem.Id;
  }

  tabSwitch(event) {

    

    this.currentTab = event.index;

    this.loadCurrentGrid();
  }

  printInvoice() {
    var getInvoiceList = new IDList();
    getInvoiceList.IDs = this.currentSelectedItems;


    this._orderApi.getOrderInvoices(getInvoiceList).subscribe(
      (response: any) => {
        this.generatePDFInvoice(response);
        this.loadCurrentGrid();
    });


  }

  generatePDFInvoice(orderDetails: OrderDetail[]) {
    const documentDefinition = this._invoiceService.createDocumentDefinition(orderDetails);
    let orderIDs = orderDetails.map(o => o.OrderId).sort();
    let fileName = 'Invoice-' + orderIDs[0] + '-' + orderIDs[orderIDs.length - 1];

    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  

  
}
