import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, BehaviorSubject, of, empty } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { GridDataResult, GridComponent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import {
  State,
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from '@progress/kendo-data-query';
import { GridService } from '../../services/kendo-grid/grid.service';
import { GeneralService } from '../../services/general.service';
import { HttpClient } from '@angular/common/http';
import { ProductAPI } from '../../services/apis/product.api';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  private _productApi: ProductAPI;
  public gridData: Observable<GridDataResult>;
  public state: State = {
    skip: 0,
    take: 50
  }

  @ViewChild('productGrid') productGrid: GridComponent;

  constructor(public gridService: GridService, private _generalService: GeneralService, private _http: HttpClient) {
    this._productApi = new ProductAPI(_generalService.getCurrentBaseUrl(), _http, gridService);
  }

  ngOnInit(): void {
    this.loadProducts();
  }

  loadProducts() {
    this.gridData = this.gridService.pipe(map(data => { return data; }));

    //this.gridService.getProductGrids(this.state).subscribe();
    this._productApi.getProductList(this.state).pipe(catchError(err => {
      Swal.fire('Server Error', 'Please try again later.', 'error');
      return of(empty);
    })).subscribe();
  }

  public dataStateChange(state: DataStateChangeEvent): void {

    this.state = state;
    this.loadProducts();
  }
}
