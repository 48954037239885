import { Component, OnInit } from '@angular/core';
import { ProductAPI } from '../../services/apis/product.api';
import { ProductDetail } from '../../models/product-detail.model';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import { GridService } from '../../services/kendo-grid/grid.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of, empty } from 'rxjs';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { map, catchError, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { FilterCheckboxService } from '../../services/kendo-grid/filter-checkbox.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  private _productApi: ProductAPI;
  productDetail: ProductDetail;
  productId: number;
  currentQuantity: number;
  public pageLoading = false;
  public gridData: Observable<GridDataResult>;
  public state: State = {
    skip: 0,
    take: 100
  }

  constructor(private _route: ActivatedRoute,
    private router: Router,
    public generalService: GeneralService,
    public gridService: GridService,
    public filterCheckboxService: FilterCheckboxService,
    private _http: HttpClient) {
    this._productApi = new ProductAPI(generalService.getCurrentBaseUrl(), _http, gridService);
    }

  ngOnInit(): void {
    this.pageLoading = true;
    this._route.params.subscribe(
      (params: any) => {

        if (params.productId) {
          this.productId = params.productId;
          this.loadProduct(params.productId);
          this.loadProductOrders();

        } else {
          this.router.navigate(['/products/dashboard']);
        }
      }
    )
  }

  loadProduct(productId: number) {
    this._productApi.getProduct(productId)
      .pipe(catchError(err => {
        Swal.fire('Server Error', 'Please try again later.', 'error');
        return of(empty);
      }), finalize(() => { this.pageLoading = false; }))
      .subscribe(
      (response: any) => {
          this.productDetail = response;
          this.currentQuantity = this.productDetail.StockQuantity;
      }
    );
  }

  loadProductOrders() {
    this.gridData = this.gridService.pipe(map(data => { return data; }));

    //this.gridService.getOrderGrids(this.state).subscribe();
    this._productApi.getProductOrderList(this.productId, this.state).pipe(catchError(err => {
      Swal.fire('Server Error', 'Please try again later.', 'error');
      return of(empty);
    })).subscribe();
  }

  MarkOutOfStock() {
    this.pageLoading = true;
    this._productApi.markOutOfStock(this.productId, this.productDetail.AdminOutOfStock ? 1 : 0)
      .pipe(catchError(err => {
        Swal.fire('Server Error', 'Please try again later.', 'error');
        return of(empty);
      }), finalize(() => { this.pageLoading = false; }))
      .subscribe();
  }

  quantityUpdate(isUpdate: boolean) {
    if (isUpdate === true) {
      this.pageLoading = true;
      this._productApi.updateProductQuantity(this.productId, this.currentQuantity)
        .pipe(catchError(err => {
          Swal.fire('Server Error', 'Please try again later.', 'error');
          return of(empty);
        }), finalize(() => { this.pageLoading = false; }))
        .subscribe((response: any) => {
          this.productDetail.StockQuantity = this.currentQuantity;
        });
    }
    else {
      this.currentQuantity = this.productDetail.StockQuantity;
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {

    this.state = state;
    this.loadProductOrders();
  }

}
