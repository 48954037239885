import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderAPI } from '../../services/apis/order.api';
import { GeneralService } from '../../services/general.service';
import { GridService } from '../../services/kendo-grid/grid.service';
import { HttpClient } from '@angular/common/http';
import { OrderDetail } from '../../models/order-detail.model';
import { ResourceService } from '../../services/resource.service';
import { OrderNote } from '../../models/order-note.model';
import { catchError, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { empty, of } from 'rxjs';
import { faTrashAlt, faPrint } from '@fortawesome/free-solid-svg-icons';
import { OrderRefundDetail } from '../../models/order-refund-detail.model';
import { IDList } from '../../models/idlist.model';
import { InvoiceService } from '../../services/invoice.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  private _orderApi: OrderAPI;
  orderDetail: OrderDetail;
  public pageLoading = false;
  newOrderNote: OrderNote;
  faTrashAlt = faTrashAlt;
  faPrint = faPrint;
  otherRefunds: OrderRefundDetail[];

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    public generalService: GeneralService,
    public resourceService: ResourceService,
    public gridService: GridService,
    private _http: HttpClient,
    private _invoiceService: InvoiceService
  ) {
    this._orderApi = new OrderAPI(generalService.getCurrentBaseUrl(), _http, gridService);
    this.newOrderNote = new OrderNote();
  }

  ngOnInit(): void {
    this.pageLoading = true;
    this.resourceService.loadStatuses();

    this._route.params.subscribe(
      (params: any) => {

        if (params.orderId) {

          this.loadOrder(params.orderId);

        } else {
          this.router.navigate(['/orders/dashboard']);
        }
      }
    )

  }

  loadOrder(orderId: number) {
    this._orderApi.getOrder(orderId).pipe(catchError(err => {
      Swal.fire('Server Error', 'Please try again later.', 'error');
      return of(empty);
    }), finalize(() => { this.pageLoading = false; })).subscribe(
      (response: any) => {
        this.orderDetail = response;
        this.otherRefunds = this.orderDetail.OrderRefunds.filter(o => o.IsOther === true);
        console.log(this.orderDetail.OrderRefunds);
      }
    );
  }

  updateOrderStatus($event) {
    this.pageLoading = true;
    this._orderApi.orderStatusUpdate(this.orderDetail.Id, $event.value)
      .pipe(catchError(err => {
        Swal.fire('Server Error', 'Please try again later.', 'error');
        return of(empty);
      }), finalize(() => { this.pageLoading = false; }))
      .subscribe(
      (response: any) => {
        this.loadOrder(this.orderDetail.Id);
      }
    );
  }

  onNoteSubmit() {
    this.pageLoading = true;
    this._orderApi.insertOrderNotes(this.orderDetail.Id, this.newOrderNote)
      .pipe(catchError(err => {
        Swal.fire('Server Error', 'Please try again later.', 'error');
        return of(empty);
      }), finalize(() => { this.pageLoading = false; }))
      .subscribe(
      (response: any) => {
        this.newOrderNote.CreatedAt = new Date();
          this.orderDetail.OrderNotes.unshift(this.newOrderNote);
        this.newOrderNote = new OrderNote();
      }
    );
  }

  PALCheck() {
    this.pageLoading = true;
    this._orderApi.checkPALOk(this.orderDetail.Id, this.orderDetail.PALChecked ? 1 : 0)
      .pipe(catchError(err => {
        Swal.fire('Server Error', 'Please try again later.', 'error');
        return of(empty);
      }), finalize(() => { this.pageLoading = false; }))
      .subscribe();
  }

  onNoteDelete(noteId) {
    this.pageLoading = true;
    this._orderApi.deleteOrderNote(noteId)
      .pipe(catchError(err => {
        Swal.fire('Server Error', 'Please try again later.', 'error');
        return of(empty);
      }), finalize(() => { this.pageLoading = false; }))
      .subscribe(
        (response: any) => {
          this.orderDetail.OrderNotes = this.orderDetail.OrderNotes.filter(o => o.Id !== noteId);
        }
        
      );
  }

  PartiallyShipped(){
    this.pageLoading = true;
    this._orderApi.partiallyShipped(this.orderDetail.Id, this.orderDetail.IsPartiallyShipped ? 1 : 0)
      .pipe(catchError(err => {
        Swal.fire('Server Error', 'Please try again later.', 'error');
        return of(empty);
      }), finalize(() => { this.pageLoading = false; }))
      .subscribe();
  }

  printInvoice() {
    var getInvoiceList = new IDList();
    getInvoiceList.IDs.push(this.orderDetail.Id);


    this._orderApi.getOrderInvoices(getInvoiceList).subscribe(
      (response: any) => {
        this.generatePDFInvoice(response);
      });


  }

  generatePDFInvoice(orderDetails: OrderDetail[]) {
    const documentDefinition = this._invoiceService.createDocumentDefinition(orderDetails);
    let fileName = 'Invoice-' + this.orderDetail.OrderId;

    pdfMake.createPdf(documentDefinition).download(fileName);
  }
}
