import { NgModule } from '@angular/core';
import { ProductsComponent } from './products.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetailsComponent } from './details/details.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { productRoutes } from './products.routing';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(productRoutes)
  ],
  declarations: [
    ProductsComponent,
    DashboardComponent,
    DetailsComponent
  ]
})
export class ProductsModule {}
