<ul>
  <li *ngIf="showFilter">
    <input class="k-textbox" (input)="onInput($event)" />
  </li>
  <!--<li (click)="onSelectionChange('_blank')"
      [ngClass]="{'k-state-selected': isItemSelected('_blank')}">
    <input type="checkbox"
           id="chk-null"
           class="k-checkbox"
           [checked]="isItemSelected('_blank')" />
    <label class="k-multiselect-checkbox k-checkbox-label"
           for="chk-null">
      Blank
    </label>
  </li>-->
  <li *ngFor="let item of currentData; let i = index;"
      (click)="onSelectionChange(valueAccessor(item))"
      [ngClass]="{'k-state-selected': isItemSelected(item)}">
    <input type="checkbox"
           id="chk-{{valueAccessor(item)}}"
           class="k-checkbox"
           [checked]="isItemSelected(item)" />
    <label class="k-multiselect-checkbox k-checkbox-label"
           for="chk-{{valueAccessor(item)}}">
      {{ textAccessor(item) }}
    </label>
  </li>
</ul>
