import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from '../services/auth/auth-guard.service';
import { PackingComponent } from './packing.component';

export const packingRoutes: Routes = [
  {
    path: 'packing',
    component: PackingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      }


    ]
  }
];
