import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { Login } from '../../models/login.model';
import { Token } from '../../models/token.model';
import { GeneralService } from '../general.service';
//import { JwtHelperService } from '@auth0/angular-jwt'
import * as jwt_decode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;
  private loggedUserRole: string;
  private _isAuthSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuthenticated: Observable<boolean> = this._isAuthSubject.asObservable();

  constructor(private http: HttpClient, private _generalService: GeneralService) { }

  login(user: Login): Observable<boolean> {
    return this.http.post<any>(this._generalService.getCurrentBaseUrl() + 'api/Auth/login', user)
      .pipe(
        tap(tokens => this.doLoginUser(user.UserName, tokens)),
        mapTo(true));
  }

  logout() {
    return this.http.post<any>(this._generalService.getCurrentBaseUrl() + 'api/Auth/logout', {
      'refreshToken': this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        return of(false);
      }));
  }

  isLoggedIn() {
    const token = localStorage.getItem(this.JWT_TOKEN);
    //var validToken = token && !this.jwtHelper.isTokenExpired(token);
    let validToken = !!token;
    if (token) {
      const decodedToken = jwt_decode(token);
      const expire = new Date(decodedToken.exp * 1000);
      validToken = expire > new Date();
      this.loggedUserRole = decodedToken.Role;
    }
    

    this._isAuthSubject.next(!!validToken);
    return !!validToken;
    //return !!this.getJwtToken();
  }

  refreshToken() {
    return this.http.post<any>(this._generalService.getCurrentBaseUrl() + 'api/Auth/Refresh', {
      'JWT': this.getJwtToken(),
      'RefreshToken': this.getRefreshToken()
    }).pipe(tap((tokens: Token) => {
      //this.storeJwtToken(tokens.JWT);
      this.storeTokens(tokens);
    }));
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  getUserRoles() {
    return this.loggedUserRole;
  }

  private doLoginUser(username: string, tokens: Token) {
    this.loggedUser = username;
    this.storeTokens(tokens);
    this._isAuthSubject.next(true);
  }

  public doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
    this._isAuthSubject.next(false);
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Token) {
    localStorage.setItem(this.JWT_TOKEN, tokens.JWT);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.RefreshToken);
  }

  public removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
