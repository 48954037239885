import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(@Inject('Window') private _window: Window) {

  }

  getCurrentBaseUrl(): string{
    let currentUrl = this._window.location.origin;

    if (currentUrl.includes('localhost')) {
      currentUrl = 'https://localhost:44322';
    }

    return currentUrl + '/';
  }

  convertOrderStatus(status: string): string {
    switch (status) {
      case 'on-hold':
        return 'warning';
      case 'processing':
        return 'success';
      case 'completed':
        return 'dark';
      case 'cancelled':
        return 'secondary';
      case 'pending':
        return 'info';
      case 'refunded':
        return 'primary';
      case 'failed':
        return 'danger';
      case 'trash':
        return 'light';
    }
  }
}
