import { Routes } from '@angular/router';
import { ImportComponent } from './import/import.component';
import { AdminComponent } from './admin.component';
import { AuthGuard } from '../services/auth/auth-guard.service';
import { ReportComponent } from './report/report.component';

export const adminRoutes: Routes = [
  {
    path: 'manageadmin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: {
      roleTypes: "report"
    },
    children: [
      {
        path: '',
        redirectTo: 'report',
        pathMatch: 'full'
      },
      {
        path: 'report',
        component: ReportComponent
      },
      {
        path: 'import',
        component: ImportComponent
      }


    ]
  }
];
