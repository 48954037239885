import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { GeneralService } from '../../services/general.service';
import { OrderAPI } from '../../services/apis/order.api';
import { GridService } from '../../services/kendo-grid/grid.service';
import { OrderIdLookup } from '../../models/order-id-lookup.model';
import { catchError, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { empty, of } from 'rxjs';
import { PackingLineItem } from '../../models/packing-line-item.model';
import { PackingsummaryService } from '../../services/packingsummary.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { IDList } from '../../models/idlist.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-packing-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('orderIdSearch') orderIdSearchElement: ElementRef;
  public pageLoading = false;
  private _orderApi: OrderAPI;
  selectedIds: OrderIdLookup[] = [];
  selectedIdString: string;
  searchingOrderId;


  constructor(public generalService: GeneralService,
    private _http: HttpClient,
    public gridService: GridService,
    private _packingSummaryService: PackingsummaryService,
    private datePipe: DatePipe) {
    this._orderApi = new OrderAPI(generalService.getCurrentBaseUrl(), _http, gridService);
  }


  ngOnInit(): void {
     
  }

  searchOrder() {
    if (!this.pageLoading) {
      this.pageLoading = true;
    }

    this._orderApi.getOrderIdByWCId(this.searchingOrderId).pipe(catchError(err => {
      Swal.fire('Invalid Order Number', 'Please try again or contact admin', 'error');
      return of(empty);
    }), finalize(() => { this.pageLoading = false; })).subscribe(
      (response: OrderIdLookup) => {
        if (response) {
          this.selectedIds.push(response);
          this.selectedIdString = this.selectedIds.map(o => o.WCId).join(', ');
          this.searchingOrderId = '';
          this.orderIdSearchElement.nativeElement.focus();
        }
        else {
          Swal.fire('Invalid Order Number', 'Please try again', 'error');
        }
        
      }
    );
  }

  generatePacking() {
    var idModel: IDList = new IDList();
    idModel.IDs = this.selectedIds.map(o => o.Id);
    this._orderApi.getOrderPackingSummary(idModel).subscribe((response: any) => {
      this.generatePackingSummary(response);
    });
   
  }

  generatePackingSummary(lineItems: PackingLineItem[]) {
    const documentDefinition = this._packingSummaryService.createDocumentDefinition(this.selectedIds, lineItems);
    const currentDateTime = new Date();
    const newDateFormat = this.datePipe.transform(currentDateTime, 'yyyy-MM-dd-H-m-s');
    let fileName = 'PackingSummary' + newDateFormat;

    pdfMake.createPdf(documentDefinition).download(fileName);

    this.selectedIds = [];
    this.searchingOrderId = '';
    this.selectedIdString = '';
  }

  
}
