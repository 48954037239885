<div class="container">
  <div class="row text-center">
    <div class="col-6 ">
      <mat-form-field class="float-right">
        <mat-label>Report Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="reportDate" disabled>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
        
      </mat-form-field>

    </div>
    <div class="col-6">
      <button mat-button mat-stroked-button color="primary" class="float-left mt-3" (click)="generateReport()">Get Report</button>
    </div>
  </div>
</div>
