import { NgModule } from '@angular/core';

import { ImportComponent } from './import/import.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { adminRoutes } from './admin.routing';
import { ReportComponent } from './report/report.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(adminRoutes)
  ],
  declarations: [
    ImportComponent,
    ReportComponent
  ]
})
export class AdminModule { }
