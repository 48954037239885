import { Injectable } from '@angular/core';
import { PackingLineItem } from '../models/packing-line-item.model';
import { OrderIdLookup } from '../models/order-id-lookup.model';

@Injectable({
  providedIn: 'root'
})
export class PackingsummaryService {

  constructor() { }

  createDocumentDefinition(selectedIds: OrderIdLookup[], packingSummaryList: PackingLineItem[]) {

    var documentObj = {
      pageSize: 'LETTER',
      content: [],
      defaultStyle: {
        fontSize: 10
      }
    };

    documentObj.content.push({
      text: 'Items',
      bold: true,
      margin: [0, 10, 0, 20],
      fontSize: '20'
    });

    const selectedWCIds = selectedIds.map(o => o.WCId);
    const selectedWCIdString = selectedWCIds.join(", ");

    documentObj.content.push({
      text: 'Orders: ' + selectedWCIdString,
      bold: false,
      margin: [0, 10, 0, 20],
      fontSize: '15'
    });

    var productGrid = {
      layout: 'lightHorizontalLines',
      table: {

        headerRows: 1,
        widths: ['40%', '15%', '9%', '9%', '9%', '9%', '9%'],
        body: [
          [
            {
              text: 'Product',
              fontSize: 12,
              bold: true
            },
            {
              text: 'SKU',
              fontSize: 12,
              bold: true
            },
            {
              text: 'Quantity',
              fontSize: 12,
              bold: true
            },
            {
              text: 'Total',
              fontSize: 12,
              bold: true
            },
            {
              text: 'GST',
              fontSize: 12,
              bold: true
            },
            {
              text: 'HST',
              fontSize: 12,
              bold: true
            },
            {
              text: 'Shipping',
              fontSize: 12,
              bold: true
            }
          ],
          ...packingSummaryList.map(o => {
            return [
                o.ProductName,
                o.Sku,
              o.Quantity,
              o.Amount,
              o.Gst,
              o.Hst,
              o.ShippingRate
              ];
          })
        ]
      },
      margin: [0, 20, 0, 5]
    };
    documentObj.content.push(productGrid);

    return documentObj;
  }
}
