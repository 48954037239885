<div class="grid-container">
  <h2 class="my-3">Products</h2>
  <form novalidate #dashboardGridForm="ngForm" class="grid-form">
    <div *ngIf="gridService.gridLoading" class="k-i-loading gridLoading-state"></div>
    <kendo-grid #productGrid
                [data]="gridData | async"
                [pageSize]="state.take"
                [skip]="state.skip"
                [sort]="state.sort"
                [sortable]="{
                        allowUnsort: true,
                        mode: 'multiple',
                        showIndexes: false
                        }"
                [resizable]="true"
                [pageable]="true"
                [reorderable]="true"
                [scrollable]="'scrollable'"
                [groupable]="false"
                filterable="menu"
                [filter]="state.filter"
                (dataStateChange)="dataStateChange($event)">
      
      <kendo-grid-column field="Name"
                         title="Name"
                         width="60">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
          <a href="/products/detail/{{dataItem.Id}}" target="_blank">{{dataItem.Name}}</a>
        </ng-template>

      </kendo-grid-column>

      <kendo-grid-column field="SKU"
                         title="SKU"
                         width="60">
      </kendo-grid-column>
      <kendo-grid-column field="Price"
                         title="Price"
                         [filterable]="false"
                         width="60">
      </kendo-grid-column>
      <kendo-grid-column field="StockQuantity"
                         title="StockQuantity"
                         width="60">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-numeric-filter-menu [column]="column"
                                          [filter]="filter"
                                          [filterService]="filterService">
          </kendo-grid-numeric-filter-menu>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="ProductType"
                         title="Product Type"
                         width="60">
      </kendo-grid-column>

    </kendo-grid>
  </form>


</div>
