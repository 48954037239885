import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataSourceRequestState, toDataSourceRequestString, DataResult, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KendoDataService {

  constructor(private http: HttpClient) { }

  public fetch(state: DataSourceRequestState, destURL: string): Observable<DataResult> {
    const queryStr = `${toDataSourceRequestString(state)}`; // Serialize the state
    const hasGroups = state.group && state.group.length;


    return this.http
      .get(`${destURL}?${queryStr}`) // Send the state to the server
      .pipe(
        map(
          (res : any) =>
            ({
              data: res.Data,
              total: res.Total
            } as GridDataResult)
        ));

    //return this.http
    //  .get(`${destURL}?${queryStr}`) // Send the state to the server
    //  .pipe(
    //    map(
    //      (
    //        { data, total }: GridDataResult
    //      ): GridDataResult =>
    //      {
    //        return {
    //          data: hasGroups ? translateDataSourceResultGroups(data) : data,
    //          total: total
    //        };
    //      }
    //  ));
  }
}
