<div *ngIf="pageLoading" class="k-i-loading gridLoading-state"></div>
<div class="container-fluid order-detail" *ngIf="orderDetail">
  <div class="row">
    <div class="col-5 ">
        <div class="row">
          <mdb-card class="w-100">
            <mdb-card-header><span class="order-title mr-1">Order #{{orderDetail.OrderId}}</span><span class="order-date"> at {{orderDetail.OrderDate | date:'yyyy-MM-dd HH:mm'}}</span> <span class="ml-3 order-status badge badge-{{generalService.convertOrderStatus(orderDetail.OrderStatus)}}">{{orderDetail.OrderStatus | uppercase}}</span><span><button mat-icon-button aria-label="Print Invoice" (click)="printInvoice()"><fa-icon [icon]="faPrint"></fa-icon></button></span></mdb-card-header>
            <mdb-card-body>
              <div class="row">
                <div class="{{orderDetail.PALNumber && orderDetail.PALNumber != '' ? 'col-4' : 'col-6' }}">
                  <mdb-card-title>
                    <h5>Billing</h5>
                  </mdb-card-title>
                  <mdb-card-text *ngIf="orderDetail.BillingAddress">
                    {{orderDetail.BillingAddress.FirstName + " " + orderDetail.BillingAddress.LastName}}<br />
                    {{orderDetail.BillingAddress.Company}}<br />
                    {{orderDetail.BillingAddress.AddressOne + " " + orderDetail.BillingAddress.AddressTwo}}<br />
                    {{orderDetail.BillingAddress.City + " " + orderDetail.BillingAddress.State + " " + orderDetail.BillingAddress.PostalCode}}<br />
                    {{orderDetail.BillingAddress.Phone}}<br />
                    {{orderDetail.BillingAddress.Email}}<br />
                  </mdb-card-text>
                </div>
                <div class="{{orderDetail.PALNumber && orderDetail.PALNumber != '' ? 'col-4' : 'col-6' }}">
                  <mdb-card-title>
                    <h5>Shipping</h5>
                  </mdb-card-title>
                  <mdb-card-text *ngIf="orderDetail.ShippingAddress">
                    {{orderDetail.ShippingAddress.FirstName + " " + orderDetail.ShippingAddress.LastName}}<br />
                    {{orderDetail.ShippingAddress.Company}}<br />
                    {{orderDetail.ShippingAddress.AddressOne + " " + orderDetail.ShippingAddress.AddressTwo}}<br />
                    {{orderDetail.ShippingAddress.City + " " + orderDetail.ShippingAddress.State + " " + orderDetail.ShippingAddress.PostalCode}}<br />
                    <mat-checkbox [(ngModel)]="orderDetail.IsPartiallyShipped" name="IsPartiallyShipped" (change)="PartiallyShipped()" class="font-weight-bold">Partially Shipped</mat-checkbox>
                  </mdb-card-text>
                </div>
                <div class="col-4" *ngIf="orderDetail.PALNumber && orderDetail.PALNumber != ''">
                  <mdb-card-title>
                    <h5>PAL</h5>
                  </mdb-card-title>
                  <mdb-card-text>
                    Number: {{orderDetail.PALNumber}}<br />
                    DOB: {{orderDetail.PALDOB}}<br />
                    Gun Club: {{orderDetail.PALGunClub}}<br />
                    Place of Birth: {{orderDetail.PALPlaceOfBirth}}<br />
                    <mat-checkbox [(ngModel)]="orderDetail.PALChecked" name="PALChecked" (change)="PALCheck()" class="font-weight-bold">PAL Checked</mat-checkbox>
                  </mdb-card-text>
                </div>
              </div>
              <div *ngIf="orderDetail.CustomerNote && orderDetail.CustomerNote !== '' " class="row customer-note">
                <div class="col-12">
                  <div class="row">
                    <mdb-card-title>
                      <h5>Customer Notes</h5>
                    </mdb-card-title>
                  </div>
                  <div class="row">
                    <mdb-card-text>
                      {{orderDetail.CustomerNote}}
                    </mdb-card-text>
                  </div>
                </div>

               
              </div>
              <div class="row">
                <div class="col-5">
                  <h5 class="pt-3">Change Order Status: </h5>
                </div>
                <div class="col-5">
                  <mat-form-field>
                    <mat-label>Order Status</mat-label>
                    <mat-select [(value)]="orderDetail.OrderStatusId" (selectionChange)="updateOrderStatus($event)">
                      <mat-option *ngFor="let status of resourceService.statuses" [value]="status.Id">
                        {{status.Name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

            </mdb-card-body>
          </mdb-card>
        </div>
        <div class="row mt-4">
          <mdb-card class="w-100">
            <mdb-card-header>Order Notes</mdb-card-header>
            <mdb-card-body>

              <div class="row order-note" *ngFor="let note of orderDetail.OrderNotes">
                <div class="col-9 order-note-content {{note.IsSystemNote ? 'pink lighten-4' : note.IsCustomerNote ? 'blue lighten-4' : 'grey lighten-2'}}">{{note.Notes}}</div>
                <div class="col-3 pr-0"><span>{{note.CreatedAt | date: 'yyyy-MM-dd HH:mm'}}</span><br /><span class="badge badge-{{note.IsSystemNote ? 'warning' : note.IsCustomerNote ? 'info' : 'primary'}}">{{note.IsSystemNote ? 'System Note' : note.IsCustomerNote ? 'Customer Note' : 'Private Note'}}</span><button mat-icon-button color="warn" aria-label="Delete this note" (click)="onNoteDelete(note.Id)"><fa-icon [icon]="faTrashAlt"></fa-icon></button></div>
                <!--<div class="col-1 pl-0" ><button mat-icon-button color="warn" aria-label="Delete this note" (click)="onNoteDelete(note.Id)"><fa-icon [icon]="faTrashAlt"></fa-icon></button></div>-->
              </div>
              <div class="row add-note">
                <form class="note-form w-100">
                  <div class="col-9">
                    <mat-form-field class="w-100">
                      <mat-label>Leave a note</mat-label>
                      <textarea matInput [(ngModel)]="newOrderNote.Notes" name="Notes"></textarea>
                    </mat-form-field>

                  </div>
                  <div class="col-3">
                    <mat-checkbox [(ngModel)]="newOrderNote.IsCustomerNote" name="IsCustomerNote">Customer Note</mat-checkbox>
                    <button mat-button mat-stroked-button color="primary" (click)="onNoteSubmit()">Submit</button>
                  </div>
                </form>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
    </div>
    <div class="col-7">
      <mdb-card class="product-table">
        <mdb-card-header>Order Products</mdb-card-header>
        <mdb-card-body>
          <div class="row product-table-header">
            <div class="col-8">
              Product
            </div>
            <div class="col-1 text-center">
              Cost
            </div>
            <div class="col-1 text-center">
              Qty
            </div>
            <div class="col-1 text-center">
              Total
            </div>
            <div class="col-1 text-center">
              {{orderDetail.TaxName}}
            </div>
          </div>
          <div class="row product-table-body" *ngFor="let product of orderDetail.OrderItems">
            <div class="col-8">
              <a href="/products/detail/{{product.ProductId}}">{{product.ProductName}}</a><br />
              <span class="product-sku">{{product.ProductSKU}}</span><span *ngIf="product.AdminOutOfStock" class="badge badge-warning ml-2">Backorder</span>
            </div>
            <div class="col-1 text-center">
              {{product.ProductPrice}}
            </div>
            <div class="col-1 text-center">
              <span>{{product.Quantity}}</span>
              <span *ngIf="product.RefundQuantity" class="product-refund">{{product.RefundQuantity}}</span>
            </div>
            <div class="col-1 text-center">
              {{product.Total}}
              <span *ngIf="product.RefundTotal" class="product-refund">{{product.RefundTotal}}</span>
            </div>
            <div class="col-1 text-center">
              {{orderDetail.TaxName ? product.Tax : ''}}
              <span *ngIf="product.RefundTax" class="product-refund">{{product.RefundTax}}</span>
            </div>
          </div>
          <div class="row product-table-body">
            <div class="col-8">
              Shipping - {{orderDetail.ShippingMethod}}
            </div>
            <div class="col-1 text-center">
              -
            </div>
            <div class="col-1 text-center">
              -
            </div>
            <div class="col-1 text-center">
              {{orderDetail.ShippingFee}}
            </div>
            <div class="col-1 text-center">
              {{orderDetail.TaxName ? orderDetail.ShippingTax : ''}}
            </div>
          </div>
          <div class="row product-table-body refund-row" *ngFor="let refund of otherRefunds">
            <div class="col-8">
              Other Refunds
            </div>
            <div class="col-1 text-center">
              -
            </div>
            <div class="col-1 text-center">
              -
            </div>
            <div class="col-1 text-center">
              -{{refund.Total}}
            </div>
            <div class="col-1 text-center">
              -
            </div>
          </div>
          <div class="row product-table-footer">
            <div class="col-12">
              <div class="row">
                <div class="col-8">

                </div>
                <div class="col-2">
                  Shipping:
                </div>
                <div class="col-2">
                  {{orderDetail.ShippingFee}}
                </div>
              </div>
              <div class="row" *ngIf="orderDetail.Insurance && orderDetail.Insurance > 0">
                <div class="col-8">

                </div>
                <div class="col-2">
                  Shipping Insurance:
                </div>
                <div class="col-2">
                  {{orderDetail.Insurance}}
                </div>
              </div>
              <div class="row" *ngIf="orderDetail.TaxName">
                <div class="col-8">

                </div>
                <div class="col-2">
                  {{orderDetail.TaxName}}:
                </div>
                <div class="col-2">
                  {{orderDetail.Tax}}
                </div>
              </div>
              <div class="row">
                <div class="col-8">

                </div>
                <div class="col-2">
                  Total:
                </div>
                <div class="col-2">
                  {{orderDetail.Total}}
                </div>
              </div>
              <div class="row refund-row" *ngIf="orderDetail.TotalRefund">
                <div class="col-8">

                </div>
                <div class="col-2">
                  Refund:
                </div>
                <div class="col-2">
                  {{orderDetail.TotalRefund}}
                </div>
              </div>
            </div>

          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>
