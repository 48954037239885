import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductsComponent } from './products.component';
import { DetailsComponent } from './details/details.component';
import { AuthGuard } from '../services/auth/auth-guard.service';

export const productRoutes: Routes = [
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
    //data: {
    //  roleTypes: HarmonizationRead
    //},
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'detail/:productId',
        component: DetailsComponent
      }


    ]
  }
];
