<div *ngIf="pageLoading" class="k-i-loading gridLoading-state"></div>
<div class="container">
 
  <div class="row p-3 order-id-container rounded">
    <div class="col-3 border-right border-dark">
      <form (submit)="searchOrder()">
        <mat-form-field class="mr-3 w-50">
          <mat-label>Order Id</mat-label>
          <input [(ngModel)]="searchingOrderId" type="text" placeholder="Order Id" aria-label="Number" matInput #orderIdSearch name="searchingOrderId">
        </mat-form-field>
        <div class="d-inline-block">
          <button mat-button mat-flat-button color="warn" type="submit" [disabled]="searchingOrderId == ''">Add</button>
        </div>
      </form>
        

    </div>
    <div class="col-9">
      <h3>Packing Orders:</h3>
        <p> 
          {{ selectedIdString }}
        </p>

    </div>

  </div>
  <div class="row">
    <div class="col-12">
      <button mat-button mat-stroked-button color="primary" class="float-right mt-3" (click)="generatePacking()" [disabled]="!selectedIds || selectedIds.length == 0">Get Packing List</button>
    </div>
  </div>
</div>
