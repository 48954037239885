<div class="container text-center my-3">
  <div class="w-50 m-auto">
    <form #loginForm="ngForm" (ngSubmit)="submitLogin()" class="text-center border border-light p-5 ">

      <p class="h4 mb-4">Sign in</p>

      <input [(ngModel)]="currentLogin.UserName"
             type="text"
             placeholder="UserName"
             name="UserName"
             class="form-control mb-4"
             required />

      <input [(ngModel)]="currentLogin.Password"
             type="password"
             placeholder="Password"
             name="Password"
             class="form-control mb-4"
             required />
      <div class="text-center">
        <button mdbBtn class="btn btn-info btn-block my-4 waves-effect waves-light" type="submit" [disabled]="isAuthenticating">
          <span class="pr-1" *ngIf="isAuthenticating"><fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> </span>
          <span *ngIf="isAuthenticating">Authenticating</span>
          <span *ngIf="!isAuthenticating">Sign in</span> 
        </button>
      </div>


    </form>
  </div>
  
</div>
