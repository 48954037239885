import { NgModule } from '@angular/core';
import { OrdersComponent } from './orders.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetailsComponent } from './details/details.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { orderRoutes } from './orders.routing';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(orderRoutes)
  ],
  declarations: [
    OrdersComponent,
    DashboardComponent,
    DetailsComponent
  ]
})
export class OrdersModule {}
