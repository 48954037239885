import { Injectable } from '@angular/core';
import { GeneralResource } from '../../models/general-resource.model';

@Injectable({
  providedIn: 'root'
})
export class FilterCheckboxService {
  public orderStatusFilters: string[] = [];

  constructor() {

  }

  public loadOrderStatus() {
    this.orderStatusFilters = [
      'processing',
      'on-hold',
      'completed',
      'pending',
      'cancelled',
      'failed',
      'refunded'
    ];
  }
}
