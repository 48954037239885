<div class="grid-container">
  <!--<h2 class="my-3">Orders</h2>-->
  <mat-tab-group (selectedTabChange)="tabSwitch($event)">


    <mat-tab label="All Orders">
      <form novalidate #allOrderGridForm="ngForm" class="grid-form">
        <div *ngIf="gridService.gridLoading" class="k-i-loading gridLoading-state"></div>
        <kendo-grid #orderGrid
                    [data]="allOrderGridData | async"
                    [pageSize]="allOrderState.take"
                    [skip]="allOrderState.skip"
                    [sort]="allOrderState.sort"
                    [sortable]="{
                        allowUnsort: true,
                        mode: 'multiple',
                        showIndexes: false
                        }"
                    [resizable]="true"
                    [pageable]="true"
                    [reorderable]="true"
                    [scrollable]="'scrollable'"
                    [columnMenu]="true"
                    [groupable]="false"
                    filterable="menu"
                    [filter]="allOrderState.filter"
                    [selectable]="{checkboxOnly:true}"
                    [kendoGridSelectBy]="selectedItemBy"
                    [selectedKeys]="currentSelectedItems"
                    (dataStateChange)="dataStateChangeAllOrder($event)">
          <ng-template kendoGridToolbarTemplate let-dataItem="dataItem">
            <button class='k-button' (click)="loadAllOrders();">Refresh <fa-icon class="ml-1" [icon]="iconRedo"></fa-icon></button>
            <button class='k-button' [hidden]="currentSelectedItems.length == 0" (click)="printInvoice();">Print Invoice</button>
          </ng-template>
          <kendo-grid-checkbox-column showSelectAll="true" width="30" [columnMenu]="false" [reorderable]="false"></kendo-grid-checkbox-column>
          <kendo-grid-column field="OrderId"
                             title="Order Id"
                             width="70">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
              <a href="/orders/detail/{{dataItem.Id}}" target="_blank">{{dataItem.OrderId}}</a>
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-numeric-filter-menu [column]="column"
                                              [filter]="filter"
                                              [filterService]="filterService">
              </kendo-grid-numeric-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="CustomerUserName"
                             title="Username"
                             width="70">
          </kendo-grid-column>
          <kendo-grid-column field="CustomerEmail"
                             title="Email"
                             width="80">
          </kendo-grid-column>
          <kendo-grid-column field="OrderStatus"
                             title="Status"
                             width="70">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
              <span class="order-status badge badge-{{generalService.convertOrderStatus(dataItem.OrderStatus)}}">{{dataItem.OrderStatus | uppercase}}</span>
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate
                         let-column="column"
                         let-filter="filter"
                         let-filterService="filterService">
              <app-filter-checkbox [data]="filterCheckboxService.orderStatusFilters"
                                   [field]="column.field"
                                   [currentFilter]="filter"
                                   [filterService]="filterService"
                                   [isPrimitive]="true">
              </app-filter-checkbox>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="OrderDate"
                             title="Order Date"
                             width="80">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
              {{dataItem.OrderDate | date: 'yyyy-MM-dd HH:mm'}}
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate
                         let-column="column"
                         let-filter="filter"
                         let-filterService="filterService">
              <kendo-grid-date-filter-menu [extra]="false"
                                           [column]="column"
                                           [filter]="filter"
                                           [filterService]="filterService">
              </kendo-grid-date-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="ShippingMethod"
                             title="Shipping Method"
                             width="80">
          </kendo-grid-column>
          <kendo-grid-column field="HasRestricted"
                             title="Has Restricted"
                             width="40">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-boolean-filter-menu [column]="column"
                                              [filter]="filter"
                                              [filterService]="filterService">
              </kendo-grid-boolean-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="PALNumber"
                             title="PAL Number"
                             width="70">
          </kendo-grid-column>
          <kendo-grid-column field="PALDOB"
                             title="PAL DOB"
                             width="70">
          </kendo-grid-column>
          <kendo-grid-column field="FirstInvoiceAt"
                             title="First Invoice Date"
                             width="80">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
              {{dataItem.FirstInvoiceAt | date: 'yyyy-MM-dd'}}
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate
                         let-column="column"
                         let-filter="filter"
                         let-filterService="filterService">
              <kendo-grid-date-filter-menu [extra]="false"
                                           [column]="column"
                                           [filter]="filter"
                                           [filterService]="filterService">
              </kendo-grid-date-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="ShippingAddressStr"
                             title="Shipping Address"
                             width="240">
          </kendo-grid-column>
          <kendo-grid-column field="LastNote"
                             title="Order Note"
                             width="200">
          </kendo-grid-column>
          <kendo-grid-column field="IsPartiallyShipped"
                             title="Partially Shipped"
                             width="60">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-boolean-filter-menu [column]="column"
                                              [filter]="filter"
                                              [filterService]="filterService">
              </kendo-grid-boolean-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="PaymentMethod"
                             title="Payment Method"
                             width="70">
          </kendo-grid-column>
          <kendo-grid-column field="InvoiceAmount"
                             title="Invoice Amount"
                             width="70">
          </kendo-grid-column>
        </kendo-grid>
      </form>
    </mat-tab>
    <mat-tab label="Open Orders">
      <form novalidate #openOrderGridForm="ngForm" class="grid-form">
        <div *ngIf="gridService.gridLoading" class="k-i-loading gridLoading-state"></div>
        <kendo-grid #orderGrid
                    [data]="openOrderGridData | async"
                    [pageSize]="openOrderState.take"
                    [skip]="openOrderState.skip"
                    [sort]="openOrderState.sort"
                    [sortable]="{
                        allowUnsort: true,
                        mode: 'multiple',
                        showIndexes: false
                        }"
                    [resizable]="true"
                    [pageable]="true"
                    [reorderable]="true"
                    [scrollable]="'scrollable'"
                    [columnMenu]="true"
                    [groupable]="false"
                    filterable="menu"
                    [filter]="openOrderState.filter"
                    [selectable]="{checkboxOnly:true}"
                    [kendoGridSelectBy]="selectedItemBy"
                    [selectedKeys]="currentSelectedItems"
                    (dataStateChange)="dataStateChangeOpenOrder($event)">
          <ng-template kendoGridToolbarTemplate let-dataItem="dataItem">
            <button class='k-button' (click)="loadOpenOrders();">Refresh <fa-icon class="ml-1" [icon]="iconRedo"></fa-icon></button>
            <button class='k-button' [hidden]="currentSelectedItems.length == 0" (click)="printInvoice();">Print Invoice</button>
          </ng-template>
          <kendo-grid-checkbox-column showSelectAll="true" width="30" [columnMenu]="false" [reorderable]="false"></kendo-grid-checkbox-column>
          <kendo-grid-column field="OrderId"
                             title="Order Id"
                             width="70">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
              <a href="/orders/detail/{{dataItem.Id}}" target="_blank">{{dataItem.OrderId}}</a>
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-numeric-filter-menu [column]="column"
                                              [filter]="filter"
                                              [filterService]="filterService">
              </kendo-grid-numeric-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="CustomerUserName"
                             title="Username"
                             width="70">
          </kendo-grid-column>
          <kendo-grid-column field="CustomerEmail"
                             title="Email"
                             width="80">
          </kendo-grid-column>
          <kendo-grid-column field="OrderStatus"
                             title="Status"
                             width="70">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
              <span class="order-status badge badge-{{generalService.convertOrderStatus(dataItem.OrderStatus)}}">{{dataItem.OrderStatus | uppercase}}</span>
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate
                         let-column="column"
                         let-filter="filter"
                         let-filterService="filterService">
              <app-filter-checkbox [data]="filterCheckboxService.orderStatusFilters"
                                   [field]="column.field"
                                   [currentFilter]="filter"
                                   [filterService]="filterService"
                                   [isPrimitive]="true">
              </app-filter-checkbox>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="OrderDate"
                             title="Order Date"
                             width="80">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
              {{dataItem.OrderDate | date: 'yyyy-MM-dd HH:mm'}}
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate
                         let-column="column"
                         let-filter="filter"
                         let-filterService="filterService">
              <kendo-grid-date-filter-menu [extra]="false"
                                           [column]="column"
                                           [filter]="filter"
                                           [filterService]="filterService">
              </kendo-grid-date-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="ShippingMethod"
                             title="Shipping Method"
                             width="80">
          </kendo-grid-column>
          <kendo-grid-column field="HasRestricted"
                             title="Has Restricted"
                             width="60">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-boolean-filter-menu [column]="column"
                                              [filter]="filter"
                                              [filterService]="filterService">
              </kendo-grid-boolean-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="PALNumber"
                             title="PAL Number"
                             width="70">
          </kendo-grid-column>
          <kendo-grid-column field="PALDOB"
                             title="PAL DOB"
                             width="70">
          </kendo-grid-column>
          <kendo-grid-column field="FirstInvoiceAt"
                             title="First Invoice Date"
                             width="80">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex" let-column="column">
              {{dataItem.FirstInvoiceAt | date: 'yyyy-MM-dd'}}
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate
                         let-column="column"
                         let-filter="filter"
                         let-filterService="filterService">
              <kendo-grid-date-filter-menu [extra]="false"
                                           [column]="column"
                                           [filter]="filter"
                                           [filterService]="filterService">
              </kendo-grid-date-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="ShippingAddressStr"
                             title="Shipping Address"
                             width="240">
          </kendo-grid-column>
          <kendo-grid-column field="LastNote"
                             title="Order Note"
                             width="200">
          </kendo-grid-column>
          <kendo-grid-column field="IsPartiallyShipped"
                             title="Partially Shipped"
                             width="60">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-boolean-filter-menu [column]="column"
                                              [filter]="filter"
                                              [filterService]="filterService">
              </kendo-grid-boolean-filter-menu>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </form>
    </mat-tab>

  </mat-tab-group>


  


</div>
