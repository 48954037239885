import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrdersComponent } from './orders.component';
import { DetailsComponent } from './details/details.component';
import { AuthGuard } from '../services/auth/auth-guard.service';

export const orderRoutes: Routes = [
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuard],
    //data: {
    //  roleTypes: HarmonizationRead
    //},
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'detail/:orderId',
        component: DetailsComponent
      }


    ]
  }
];
