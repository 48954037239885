import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  }
  //{
  //  path: 'error',
  //  component: ErrorPageComponent
  //},
  //{
  //  path: 'access-denied',
  //  component: AccessDeniedComponent
  //},
  //{
  //  path: '404',
  //  component: NotFoundComponent
  //},
  //{
  //  path: '**',
  //  redirectTo: '/404'
  //}
];
