import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/order.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from '../services/general.service';
import { Login } from '../models/login.model';
import { AuthService } from '../services/auth/auth.service';
import { catchError, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentLogin: Login;
  isAuthenticating = false;
  faSpinner = faSpinner;

  constructor(private router: Router, private generalService: GeneralService, private authService: AuthService) {
    
  }

  ngOnInit(): void {
    this.currentLogin = new Login();
    this.generalService.getCurrentBaseUrl();
    var isLoggedIn = this.authService.isLoggedIn();
  }

  submitLogin(): void {
    this.isAuthenticating = true;
    this.authService.login(this.currentLogin)
      .pipe(catchError(error => {
        Swal.fire('Failed', 'Invalid username or password', 'error');
        return of(undefined);
      }), finalize(() => { this.isAuthenticating = false; }))
      .subscribe(success => {
        if (success) {
          this.router.navigate(['/orders/dashboard']);
        }

      
    });
  }

}
