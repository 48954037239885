


import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { GridService } from '../kendo-grid/grid.service';





//@Injectable()
export class ImportAPI {
    private _baseUrl: string;
    private _http: HttpClient;
    private _gridService: GridService

    constructor(
        baseUrl: string,
        http: HttpClient,
        gridService: GridService = null
    ) {
        this._baseUrl = baseUrl; 
        this._http = http;
        this._gridService = gridService;
    }


    


    
    public importCategory(): Observable<void> { 
        const _Url = this._baseUrl + `api/Import/AllCategories`;               
        return this._http.get<void>(_Url);
        // .catch(error => this.handleError(error));
    }
    public importWCTag(): Observable<void> { 
        const _Url = this._baseUrl + `api/Import/AllTags`;               
        return this._http.get<void>(_Url);
        // .catch(error => this.handleError(error));
    }
    public importProducts(): Observable<void> { 
        const _Url = this._baseUrl + `api/Import/AllProducts`;               
        return this._http.get<void>(_Url);
        // .catch(error => this.handleError(error));
    }
    public importProduct(productId: number): Observable<void> { 
        const _Url = this._baseUrl + `api/Import/Product?productId=${productId}`;               
        return this._http.get<void>(_Url);
        // .catch(error => this.handleError(error));
    }
    public updateProductFromAdmin(): Observable<void> { 
        const _Url = this._baseUrl + `api/Import/ProductFromAdmin`;               
        return this._http.get<void>(_Url);
        // .catch(error => this.handleError(error));
    }
    public importCustomers(): Observable<void> { 
        const _Url = this._baseUrl + `api/Import/AllCustomers`;               
        return this._http.get<void>(_Url);
        // .catch(error => this.handleError(error));
    }
    public importOrders(fromDate: string, toDate: string, statusArray: string): Observable<void> { 
        const _Url = this._baseUrl + `api/Import/AllOrders?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}&statusArray=${encodeURIComponent(statusArray)}`;               
        return this._http.get<void>(_Url);
        // .catch(error => this.handleError(error));
    }
    public importOrder(orderId: number): Observable<void> { 
        const _Url = this._baseUrl + `api/Import/Order?orderId=${orderId}`;               
        return this._http.get<void>(_Url);
        // .catch(error => this.handleError(error));
    }
    public importPendingOrder(): Observable<void> { 
        const _Url = this._baseUrl + `api/Import/PendingOrder`;               
        return this._http.get<void>(_Url);
        // .catch(error => this.handleError(error));
    }
    public importOrderNotes(orderId: number): Observable<void> { 
        const _Url = this._baseUrl + `api/Import/OrderNotes?orderId=${orderId}`;               
        return this._http.get<void>(_Url);
        // .catch(error => this.handleError(error));
    }

    

    // Utility
    /*
    public handleError(error: HttpErrorResponse) {
        console.error("Error Caught!", error);
        let customError: string = "";
        if (error.error) {
            let errorMessage = error.status === 400 ? error.error : error.statusText;
            if (error.status === 400) {
               errorMessage = JSON.parse(errorMessage).Message;
            }  else {
                if (!error.error.message) {
                    errorMessage = error.error.Message ? error.error.Message.Message : errorMessage;                
                } else {
                    errorMessage = error.error.message;
                }
            }            
            customError = error.status === 400 ? error.error : errorMessage;
            // this._toastr.error(customError, 'An Error Has Occurred');
        } else {
        }
        return Observable.throw(customError || 'Server error');
    }*/
}
