import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routing';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/shared.module';
import { OrdersModule } from './orders/orders.module';
//import { JwtModule } from "@auth0/angular-jwt";
import { AuthGuard } from './services/auth/auth-guard.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { ProductsModule } from './products/products.module';
import { AdminModule } from './admin/admin.module';

import { ButtonsModule, InputsModule, CardsModule } from 'angular-bootstrap-md';
import { NavbarComponent } from './navbar/navbar.component';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TokenInterceptor } from './services/TokenInterceptor';
import { AdminComponent } from './admin/admin.component';
import { PackingModule } from './packing/packing.module';


//export function tokenGetter() {
//  return localStorage.getItem("jwt");
//}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    AdminComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes
    ),
    BrowserModule,
    BrowserAnimationsModule,
    OrdersModule,
    HttpClientModule,
    SharedModule,
    AdminModule,
    ProductsModule,
    ButtonsModule,
    InputsModule,
    CardsModule,
    PackingModule,
    //JwtModule.forRoot({
    //  config: {
    //    tokenGetter: tokenGetter,
    //    whitelistedDomains: ["localhost:44322"],
    //    blacklistedRoutes: [],
        
    //  }
    //}),
    GridModule,
    [SweetAlert2Module.forRoot()]
  ],
  exports: [
    ButtonsModule,
    InputsModule,
    CardsModule
  ],
  entryComponents: [
    AppComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: 'Window',
      useValue: window
    },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
