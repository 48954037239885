import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GeneralService } from './general.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (error.headers.has('token-expired') || error.headers.has('Token-Expired')) {
          return this.handle401Error(request, next);
        }
        else {
          this.router.navigate(['/home']);
          Swal.fire('Unauthorized', 'You are not authorized to visit this page. Please login or ask admin for permissions.', 'error');
          return new Observable<HttpEvent<any>>();
        }
        
      } else {
        console.log(error);
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);


      return this.authService.refreshToken().pipe(
        catchError(error => {
          this.router.navigate(['/home']);
          Swal.fire('Unauthorized', 'You are not authorized to visit this page or your session was expired. Please login or ask admin for permissions.', 'error');
          return of(undefined);
        }),
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.JWT);
          return next.handle(this.addToken(request, token.JWT)).pipe(catchError(error => {
            this.authService.removeTokens();
            this.router.navigate(['/home']);
            Swal.fire('Unauthorized', 'You are not authorized to visit this page or your session was expired. Please login or ask admin for permissions.', 'error');
            return new Observable<HttpEvent<any>>();
          }));
        })
        //, catchError(error => {
        //  this.router.navigate(['/home']);
        //  Swal.fire('Unauthorized', 'You are not authorized to visit this page or your session was expired. Please login or ask admin for permissions.', 'error');
        //  return new Observable<HttpEvent<any>>();
        //})
      );

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
