<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark black ie-nav" [containerInside]="false">
  <mdb-navbar-brand>
    <a class="logo navbar-brand" href="#"><strong>Tenda Admin</strong></a>
  </mdb-navbar-brand>
  <links >
    <div class="navbar-nav mr-auto">
      <a *ngIf="authService.isAuthenticated | async" class="nav-link" color="unique-color" routerLink="/orders" routerLinkActive="active">Orders</a>
      <a *ngIf="authService.isAuthenticated | async" class="nav-link" routerLink="/products" routerLinkActive="active">Products</a>
      <a *ngIf="authService.isAuthenticated | async" class="nav-link" routerLink="/packing" routerLinkActive="active">Packing</a>
      <a *ngIf="authService.getUserRoles() == 'report' && authService.isAuthenticated | async" class="nav-link" routerLink="/manageadmin" routerLinkActive="active">Reports</a>
      <!--<a class="nav-link" routerLink="/categories/dashboard" routerLinkActive="active">Category</a>-->
    </div>
    <!--<ul class="navbar-nav mr-auto">
      <li *ngIf="authService.isAuthenticated | async" class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link active" routerLink="/orders/dashboard" routerLinkActive="active">Order</a>
      </li>
      <li *ngIf="authService.isAuthenticated | async" class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link active" routerLink="/products/dashboard" routerLinkActive="active">Product</a>
      </li>
      <li *ngIf="authService.isAuthenticated | async" class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link" routerLink="/categories/dashboard" routerLinkActive="active">Category</a>
      </li>
    </ul>-->
    <div class="navbar-nav ml-auto">
      <a *ngIf="authService.isAuthenticated | async" class="nav-link" (click)="logout()" >Logout</a>
    </div>
  </links>
  
  
</mdb-navbar>
