import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { CompositeFilterDescriptor, distinct, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-filter-checkbox',
  templateUrl: './filter-checkbox.component.html',
  styleUrls: ['./filter-checkbox.component.scss']
})
export class FilterCheckboxComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Input() public isNumber = false;
  @Output() public valueChange = new EventEmitter<number[]>();

  public currentData: any;
  public showFilter = true;
  private value: any[] = [];
  private filterBlank = false;

  public textAccessor = (dataItem: any) => this.isPrimitive ? dataItem : dataItem[this.textField];
  public valueAccessor = (dataItem: any) => this.isPrimitive ? dataItem : dataItem[this.valueField];

  //protected valueAccessor(dataItem: any) {
  //    return this.isPrimitive ? dataItem : dataItem[this.valueField];
  //}

  //protected textAccessor(dataItem: any) {
  //    return this.isPrimitive ? dataItem : dataItem[this.textField];
  //}

  public ngAfterViewInit() {
    if (typeof this.textAccessor(this.data[0]) === 'number' && this.isNumber) {
      this.data = this.data.map(function (item) {
        return item.toString();
      });
    }

    this.currentData = this.data;
    this.value = this.currentFilter.filters.filter((o: FilterDescriptor) => o.value !== null).map((f: FilterDescriptor) => f.value);
    this.filterBlank = this.currentFilter.filters.some((o: FilterDescriptor) => o.operator === 'isnull');



    this.showFilter = typeof this.textAccessor(this.currentData[0]) === 'string';
  }

  public isItemSelected(item) {

    if (item === '_blank') {
      return this.filterBlank;
    } else {
      return this.value.some(x => x === this.valueAccessor(item));
    }

  }

  public onSelectionChange(item) {
    if (item === '_blank') {
      this.filterBlank = !this.filterBlank;
    } else {
      if (this.value.some(x => x === item)) {
        this.value = this.value.filter(x => x !== item);
      } else {
        this.value.push(item);
      }
    }

    const filterValue = this.value.map(value => ({
      field: this.field,
      operator: 'eq',
      value
    }));

    if (this.filterBlank) {
      filterValue.push({
        field: this.field,
        operator: 'isnull',
        value: null
      });
    }

    this.filterService.filter({
      filters: filterValue,
      logic: 'or'
    });
  }

  public onInput(e: any) {
    this.currentData = distinct([
      ...this.currentData.filter(dataItem => this.value.some(val => val === this.valueAccessor(dataItem))),
      ...filterBy(this.data, {
        operator: 'contains',
        field: this.textField,
        value: e.target.value
      })],
      this.textField
    );
  }
}
