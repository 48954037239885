import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//import { JwtHelperService } from '@auth0/angular-jwt'
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
    
  }
  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    var isAuthorized = this.authService.isLoggedIn();

    if (!isAuthorized) {
      this.router.navigate(['/home']);
    }

    

    if (isAuthorized && route.data && route.data.roleTypes) {
      if (route.data.roleTypes !== this.authService.getUserRoles()) {
        isAuthorized = false;
        this.router.navigate(['/orders/dashboard']);
      }
    }
    
    return isAuthorized;
  }
}
